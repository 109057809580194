import { Typography } from "@mui/material"
import { useState } from "react"
import Breadcrumbs from "src/components/Breadcrumbs"

const UnnamedGame: React.FC = () => {
  const [ gameName, setGameName ] = useState<React.ReactNode>(
    <>$&#123;Unnamed Game&#125;</>
  )
  const breadcrumbs = 'Unnamed Game'

  return (
    <>
      <Breadcrumbs data={breadcrumbs}/>
      <Typography variant="h1">Welcome to {gameName}</Typography>
      <Typography variant="h3">Overview</Typography>
      <Typography>6 player</Typography>
    </>
  )
}
export default UnnamedGame