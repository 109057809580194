import { Navigate, Route, BrowserRouter, Routes } from "react-router-dom"
import { PublicRoutesMap, ROOT_PATH, RouteType, RoutesConfig } from "./config/RoutesConfig"
import RouteWrapper from "./RouteWrapper"
import { Fragment } from "react"

const NordsRoutes: React.FC = () => {
    const constructRouteMap = (configChilds: RouteType[] | undefined): JSX.Element | JSX.Element[] => {
        if (!configChilds || configChilds.length === 0) return <></>

        return configChilds.map((routeItem: RouteType) => {
          const { id, childs, path } = routeItem

          if (childs && childs.length > 0) {
            if (path) {
              return (
                <Route key={`NordsRoute-${id}`} path={path.pathId} element={<RouteWrapper {...routeItem} />}>
                  {constructRouteMap(childs)}
                </Route>
              )
            }
      
            return <Fragment key={`NordsRoute-${id}`}>{constructRouteMap(childs)}</Fragment>
          }

          return (
            <Route
              key={`Nords-${id}`}
              path={ path?.relativeRoute ? `${path.relativeRoute.absolutePath}/${path?.pathId}` : path?.pathId }
              element={<RouteWrapper {...routeItem} />}
            />
          )
        })
    }

    return (
        <BrowserRouter>
            <Routes>
                {constructRouteMap(RoutesConfig)}
                <Route 
                  path={ROOT_PATH}
                  element={
                    <Navigate to={PublicRoutesMap.HOME.absolutePath} replace/>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Navigate to={PublicRoutesMap.PAGE_NOT_FOUND.absolutePath} replace/>
                  }
                />
            </Routes>
        </BrowserRouter>
    )
}
export default NordsRoutes