import { ThemeProvider } from "@mui/material"
import theme from "./theme"
import NordsRoutes from "./routes/Routes"

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <NordsRoutes/>
        </ThemeProvider>
    )
}
export default App