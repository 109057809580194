import { Typography } from "@mui/material"
import { styled } from "@mui/system"
import { TabPanel } from "@mui/lab"

const MainContainer = styled('div')(
    ({ theme }) => ({
        background: theme.palette.background.default,
        paddingBottom: 40,
        height: "calc(100% - 70px)",
        "& .MuiTabPanel-root": {
            height: "calc(100% - 60px)"
        }
    }
))

const ButtonContainer = styled('div')(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        "& button": {
            marginRight: 20
        },
        marginBottom: "1.2rem"
    })
)

const DeckGenerationContainer = styled('div')(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        "& :first-child": {
            marginRight: 12
        }
    })
)

const CardContainer = styled('div')(
    ({ theme }) => ({
        background: theme.palette.secondary.light,
        marginTop: 20,
        borderRadius: 25,
        padding: 20,
        whiteSpace: "pre-wrap"
    })
)

const FlavorTextContainer = styled('div')(
    ({ theme }) => ({
        marginBottom: "1.2rem"
    })
)

const DescriptionParagraph = styled(Typography)(
    ({ theme }) => ({
        marginBottom: 20
    })
)

const CardListContainer = styled(TabPanel)(
    ({ theme }) => ({
        height: "100%"
    })
)

const CardHistoryContainer = styled('div')(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.secondary.light}`,
        background: theme.palette.secondary.dark,
        borderRadius: 25,
        marginTop: 20,
        padding: 20
    })
)

const ScenarioDeckBuilderStyles = {
    MainContainer, ButtonContainer, CardContainer, FlavorTextContainer, DeckGenerationContainer, DescriptionParagraph, CardListContainer, CardHistoryContainer
}
export default ScenarioDeckBuilderStyles