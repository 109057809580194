import { Button } from "@mui/material"
import { styled } from "@mui/system"

const NordsButton = styled(Button)(({ theme }) => ({
  cursor: "pointer",
  display: "inline-flex",
  position: "relative",
  borderRadius: 5,
  padding: "12px 20px",
  fontWeight: 600,
  height: 40,
  boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.3)",
  "&:disabled": {
    cursor: "not-allowed",
    opacity: 0.3,
  },
}))

const PrimaryNordsButton = styled(NordsButton)(({ theme }) => ({
  ":disabled": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))

const SecondaryNordsButton = styled(NordsButton)(({ theme }) => ({
  ":disabled": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))

const NordsButtonStyles = {
  NordsButton,
  PrimaryNordsButton,
  SecondaryNordsButton,
}

export default NordsButtonStyles
