import { SubdirectoryArrowRight } from "@mui/icons-material"
import AppContainer from "../../components/AppContainer"
import Home from "../../pages/Home"
import SRCharacters from "../../pages/StarRail/SRCharacters"
import SRStatCalculator from "../../pages/StarRail/SRStatCalculator"
import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import DocumentationOverview from "../../pages/Documentation/DocumentationOverview"
import ScenarioDeckBuilder from "src/pages/Catan/DawnOfHumankind/ScenarioDeckBuilder"
import PageNotFound from "src/pages/PageNotFound"
import UnnamedGame from "src/pages/UnnamedGame"

export const ROOT_PATH = ""

export const PublicRoutesMap: RouteMapType = {
  HOME: {
    pathId: ROOT_PATH,
    absolutePath: ROOT_PATH,
  },
  STAR_RAIL: {
    pathId: "star-rail",
    absolutePath: "star-rail",
  },
  CATAN: {
    pathId: "catan",
    absolutePath: "catan",
  },
  UNNAMED_GAME: {
    pathId: "unnamed-game",
    absolutePath: "unnamed-game",
  },
  DOCUMENTATION: {
    pathId: "documentation",
    absolutePath: "documentation",
  },
  PAGE_NOT_FOUND: {
    pathId: "page-not-found",
    absolutePath: "page-not-found",
  },
}

const starRailRoutesMap: RouteMapType = {
  STAT_CALCULATOR: {
    pathId: "stat-calculator",
    absolutePath: `${PublicRoutesMap.STAR_RAIL.absolutePath}/stat-calculator`,
    relativeRoute: PublicRoutesMap.STAR_RAIL,
  },
  CHARACTERS: {
    pathId: "characters",
    absolutePath: `${PublicRoutesMap.STAR_RAIL.absolutePath}/characters`,
    relativeRoute: PublicRoutesMap.STAR_RAIL,
  },
}

const documentationRoutesMap: RouteMapType = {
  OVERVIEW: {
    pathId: "overview",
    absolutePath: `${PublicRoutesMap.DOCUMENTATION.absolutePath}/overview`,
    relativeRoute: PublicRoutesMap.DOCUMENTATION,
  },
}

const catanRoutesMap: RouteMapType = {
  DAWN_OF_HUMANKIND: {
    pathId: "dawn-of-humankind",
    absolutePath: `${PublicRoutesMap.CATAN.absolutePath}/dawn-of-humankind`,
    relativeRoute: PublicRoutesMap.CATAN,
  },
}

const dawnOfHumankindRoutesMap: RouteMapType = {
  SCENARIO_DECK_BUILDER: {
    pathId: "scenario-deck-builder",
    absolutePath: `${catanRoutesMap.DAWN_OF_HUMANKIND.absolutePath}/scenario-deck-builder`,
    relativeRoute: catanRoutesMap.DAWN_OF_HUMANKIND,
  },
}

export type RouteMapType = { [key: string]: PathType }

export type PathType = {
  absolutePath: string
  pathId: string
  relativeRoute?: PathType
}

export type RouteType = {
  id: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
  component?: React.ElementType
  path?: PathType
  title?: string
  childs?: RouteType[]
  showInSideNavigation?: boolean
}

export const RoutesConfig: Array<RouteType> = [
  {
    id: "root-ngg",
    component: AppContainer,
    path: PublicRoutesMap.HOME,
    title: "_root",
    childs: [
      {
        id: "page-not-found-ngg",
        component: PageNotFound,
        path: PublicRoutesMap.PAGE_NOT_FOUND,
        title: "Page Not Found",
        showInSideNavigation: false,
      },
      {
        id: "home-ngg",
        component: Home,
        path: PublicRoutesMap.HOME,
        title: "Home",
        showInSideNavigation: true,
      },
      {
        id: "sr-ngg",
        title: "Star Rail",
        showInSideNavigation: false,
        childs: [
          {
            id: "stat-calculator-sr-ngg",
            component: SRStatCalculator,
            path: starRailRoutesMap.STAT_CALCULATOR,
            title: "Stat Calculator",
          },
          {
            id: "characters-sr-ngg",
            component: SRCharacters,
            path: starRailRoutesMap.CHARACTERS,
            title: "Characters",
          },
        ],
      },
      {
        id: "catan-ngg",
        title: "Catan",
        showInSideNavigation: true,
        childs: [
          {
            id: "dawn-of-humankind-c",
            title: "Catan: Dawn of Humankind",
            showInSideNavigation: true,
            childs: [
              {
                id: "scenario-deck-builder-doh",
                component: ScenarioDeckBuilder,
                path: dawnOfHumankindRoutesMap.SCENARIO_DECK_BUILDER,
                title: "Scenario Deck Builder",
                showInSideNavigation: true,
              },
            ],
          },
        ],
      },
      {
        id: "documentation-ngg",
        title: "Documentation",
        showInSideNavigation: false,
        childs: [
          {
            id: "app-container-documentation",
            component: DocumentationOverview,
            path: documentationRoutesMap.OVERVIEW,
            icon: SubdirectoryArrowRight,
            title: "Overview",
          },
        ],
      },
      {
        id: "unnamed-game-ngg",
        component: UnnamedGame,
        path: PublicRoutesMap.UNNAMED_GAME,
        title: "Unnamed Game",
        showInSideNavigation: true
      }
    ],
  },
]
