import { Typography } from "@mui/material"
import Breadcrumbs from "src/components/Breadcrumbs"

export interface Documentation extends React.FC {

}

const DocumentationOverview: React.FC = () => {
    const breadcrumbs = ['Documentation', 'Overview']

    return (
        <>
            <Breadcrumbs data={breadcrumbs}/>
            <Typography variant="h1">Overview</Typography>
            
            <Typography color="text.nordsBlue">This section is for internal use only.</Typography>
        </>
    )
}
export default DocumentationOverview