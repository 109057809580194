import { Tab, Typography } from "@mui/material"
import { useState } from "react"
import NordsButton from "src/components/NordsButton/NordsButton"
import { DawnOfHumanityCardType, DoHCategoryType } from "src/common/types/CatanCardTypes"
import { rng } from "src/functions"
import createDawnOfHumanKindCard from "src/components/Catan/DawnOfHumankind/DawnOfHumankindCard"
import Breadcrumbs from "src/components/Breadcrumbs"
import ScenarioDeckBuilderStyles from "./ScenarioDeckBuilder.styles"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { DeckBuilderDescription, DeckBuilderIntro } from "src/common/resources/DoHCommon"
import CardList from "./CardList"
import { useDispatch, useSelector } from "react-redux"
import { setNotificationState } from "src/store/ui"
import { RootState } from "src/store"

const ScenarioDeckBuilder: React.FC = () => {
    const isNotificationVisible = useSelector((state: RootState) => state.notificationBarState.message) !== ""
    const dispatch = useDispatch()
    const breadcrumbs = ['Catan', 'Catan: Dawn of Humankind', 'Scenario Deck Builder']
    const [ deck, setDeck ] = useState<Array<DawnOfHumanityCardType>>([])
    const [ history, setHistory ] = useState<Array<DawnOfHumanityCardType>>([])
    const [ drawMessage, setDrawMessage ] = useState('')
    const [ isDeckGenerated, setIsDeckGenerated ] = useState(false)
    const [ tabs, setTabs ] = useState("1")
    const [ card, setCard ] = useState<DawnOfHumanityCardType | undefined>()

    const handleChangeTabs = (_event: React.SyntheticEvent, newValue: string) =>  setTabs(newValue)

    const drawCard = () => {  
        let cardHistory = history
        if (card) {
            cardHistory.unshift(card)
            setHistory(cardHistory)
        }

        setCard(deck.pop())
        setDrawMessage(`You drew ${card?.name} card!`)

        if (deck.length === 0) setIsDeckGenerated(false)
    }
    
    const generateDeck = () => {
        let generatedDeck: Array<DawnOfHumanityCardType> = []

        for (let i = 0; i < 40; i++) {
            const drawRng: number = rng(1, 40)
            const category: DoHCategoryType = drawRng < 21 ? "resource" : drawRng < 31 ? "encounter" : drawRng < 39 ? "occurence" : "victory"
    
            generatedDeck.push(createDawnOfHumanKindCard({ id: i.toString(), category: category }))
        }
        
        setCard(undefined)
        setDrawMessage("")
        setHistory([])

        if (!isNotificationVisible) {
            dispatch(setNotificationState({
                message: `Deck has been ${deck.length > 0 ? "re" : ""}generated.`,
                autohide: true
            }))
        }

        setDeck(generatedDeck)
        setIsDeckGenerated(true)
    }

    const getCardHistory = (): React.ReactNode => {
        return history.map((card: DawnOfHumanityCardType) => (
            <ScenarioDeckBuilderStyles.CardContainer>
            <Typography variant="h1">
                {card?.name}
            </Typography>
            <ScenarioDeckBuilderStyles.FlavorTextContainer>
                <Typography variant="h2">
                    {card?.flavorText}
                </Typography>
            </ScenarioDeckBuilderStyles.FlavorTextContainer>
            <Typography variant="h2">
                {card?.effectText}
            </Typography>
        </ScenarioDeckBuilderStyles.CardContainer>
        ))
    }

    return (
        <>
            <Breadcrumbs data={breadcrumbs}/>
            <ScenarioDeckBuilderStyles.MainContainer>
                <TabContext value={tabs}>
                    <TabList onChange={handleChangeTabs}>
                        <Tab label="About" value="1"/>
                        <Tab label="Deck Builder" value="2"/>
                        <Tab label="Card List" value="3"/>
                    </TabList>  
                    <TabPanel value="1">
                        <ScenarioDeckBuilderStyles.DescriptionParagraph>
                            {DeckBuilderIntro}
                        </ScenarioDeckBuilderStyles.DescriptionParagraph>
                        <Typography variant="h1">A Prelude to Discovery: Before We Embark</Typography>
                        {DeckBuilderDescription.map((paragraph: string) => {
                            return (
                                <ScenarioDeckBuilderStyles.DescriptionParagraph key={`card-${paragraph}`}>
                                    {paragraph}
                                </ScenarioDeckBuilderStyles.DescriptionParagraph>
                            )
                        })}
                        <Typography variant="h1">Embark on Your Journey: Unraveling the Rules</Typography>        
                    </TabPanel>   
                    <TabPanel value="2">
                        <Typography variant="h1">Scenario Deck Builder</Typography>
                        <ScenarioDeckBuilderStyles.ButtonContainer>
                            <NordsButton disabled={!isDeckGenerated} onClick={drawCard} type="primary" content="Draw Card"/>
                            <NordsButton disabled={isNotificationVisible} onClick={generateDeck} type="secondary" content="Generate Deck"/>
                            {deck.length < 40 && card?.name && card.name !== "" && (
                                <Typography variant="h4">{deck.length}/40</Typography>
                            )}
                        </ScenarioDeckBuilderStyles.ButtonContainer>
                        {(card?.name && card.name !== "") && (
                            <Typography variant="h4">You drew <strong>{card?.name}</strong> card!</Typography>
                        )}
                        {drawMessage !== "" && (
                            <ScenarioDeckBuilderStyles.CardContainer>
                                <Typography variant="h1">
                                    {card?.name}
                                </Typography>
                                <ScenarioDeckBuilderStyles.FlavorTextContainer>
                                    <Typography variant="h2">
                                        {card?.flavorText}
                                    </Typography>
                                </ScenarioDeckBuilderStyles.FlavorTextContainer>
                                <Typography variant="h2">
                                    {card?.effectText}
                                </Typography>
                            </ScenarioDeckBuilderStyles.CardContainer>
                        )}      
                        {history.length !== 0 && (
                            <ScenarioDeckBuilderStyles.CardHistoryContainer>
                                <Typography variant="h1">Draw History</Typography>
                                {getCardHistory()}
                            </ScenarioDeckBuilderStyles.CardHistoryContainer>
                        )}
                    </TabPanel>    
                    <ScenarioDeckBuilderStyles.CardListContainer value="3">
                        <CardList/>
                    </ScenarioDeckBuilderStyles.CardListContainer>           
                </TabContext>
            </ScenarioDeckBuilderStyles.MainContainer>
        </>
    )
}
export default ScenarioDeckBuilder