import { DoHCommon } from "src/common/resources/DoHCommon"
import { DawnOfHumanityCardType, DoHEventType, DoHTerrainType, DoHCategoryType } from "src/common/types/CatanCardTypes"
import { rng } from "src/functions"

type DoHCardProps = {
    id: string,
    category: DoHCategoryType
}

const DoHTerrainMap: Array<DoHTerrainType> = [
    "forest", "plains", "pastures", "mountains"
]

const createDawnOfHumanKindCard = (props: DoHCardProps): DawnOfHumanityCardType => {
    const { id, category } = props

    const terrainTypeIndex = rng(0, 3)
    const terrain: DoHTerrainType = (DoHTerrainMap[terrainTypeIndex])

    const eventTypeIndex = rng(1, 100)
    const event: DoHEventType = eventTypeIndex < 50 ? "misfortune"  : "fortune"
    

    let card: DawnOfHumanityCardType = {
        name: "N/A",
        category: "resource",
        event: "fortune",
        flavorText: "Flavor text not found.",
        effectText: "Effect text not found."
    }

    if (category === "victory") {
        const cardSearch = DoHCommon.find((DoHCard: DawnOfHumanityCardType) => DoHCard.category === "victory")
        if (cardSearch) card = cardSearch
    } else if (category === "occurence") {
        const occurenceTypeIndex = rng(1, 100)
        const occurenceType: DoHEventType = occurenceTypeIndex < 86 ? event : "challenge"
        const cardSearch = DoHCommon.find((DoHCard: DawnOfHumanityCardType) => (
            occurenceType === "challenge" ? DoHCard.event === occurenceType && DoHCard.category === "occurence" :
            DoHCard.event === event && DoHCard.category === "occurence" && DoHCard.terrain === terrain
            ))
        if (cardSearch) card = cardSearch
    } else if (category === "resource") {
        const resourceCardMap: Array<DawnOfHumanityCardType> = DoHCommon.filter((DoHCard: DawnOfHumanityCardType) => (
            DoHCard.event === event && DoHCard.category === "resource"
            ))
        if (resourceCardMap) card = resourceCardMap[rng(0, resourceCardMap.length - 1)]
    } else if (category === "encounter") {
        const resourceCardMap: Array<DawnOfHumanityCardType> = DoHCommon.filter((DoHCard: DawnOfHumanityCardType) => DoHCard.category === "encounter")
        if (resourceCardMap) card = resourceCardMap[rng(0, resourceCardMap.length - 1)] 
    }

    return {...card, 
        id: id,
        category: category,
        game: "CATAN_DAWN_OF_HUMANKIND"
    }
}
export default createDawnOfHumanKindCard