import { Typography } from "@mui/material"
import BreadcrumbsStyles from "./Breadcrumbs.styles"
import { ChevronRight } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { RootState } from "src/store"
import { useEffect, useState } from "react"

type BreadcrumbsType = {
  data: string | Array<string>
}

const Breadcrumbs: React.FC<BreadcrumbsType> = ({ data }) => {
	const { width } = useSelector((state: RootState) => state.windowDimensions)
	const [ showBreadcrumbs, setShowBreadcrumbs ] = useState(true)

	useEffect(() => {
		if (width !== 0) setShowBreadcrumbs(width > 555)
	},[ width ])
	
	const makeBreadcrumbNode = (breadcrumbs: Array<string>): React.ReactNode => {
		return breadcrumbs.map((breadcrumb: string, index: number) => {
			if (index !== breadcrumbs.length - 1) return (
				<BreadcrumbsStyles.BreadcrumbNode key={`box-${breadcrumb}`}>
					{index !== 0 && <ChevronRight key={`icon-${breadcrumb}`}/>}
					<Typography key={`text-${breadcrumb}`} variant="h4">{breadcrumb}</Typography>
				</BreadcrumbsStyles.BreadcrumbNode>
			)
			else return (
				<BreadcrumbsStyles.BreadcrumbNode key={`box-${breadcrumb}`}>
						<ChevronRight key={`icon-${breadcrumb}`}/>
						<Typography key={`text-${breadcrumb}`} variant="h3">{breadcrumb}</Typography>            
				</BreadcrumbsStyles.BreadcrumbNode>
			)
		})
	}

	const setBreadcrumbs = (): React.ReactNode => {
		if (Array.isArray(data)) {
			let breadcrumbArray: Array<string> = data

			return (
				<BreadcrumbsStyles.BreadcrumbNode>{makeBreadcrumbNode(breadcrumbArray)}</BreadcrumbsStyles.BreadcrumbNode>
			)
		} else return <Typography variant="h3">{data}</Typography>
	}

	return (showBreadcrumbs ?
		<BreadcrumbsStyles.Breadcrumb>{setBreadcrumbs()}</BreadcrumbsStyles.Breadcrumb> : <></>
	)
}
export default Breadcrumbs