import { DoHCommon } from "src/common/resources/DoHCommon"
import CardListStyles from "./CardList.styles"
import { DawnOfHumanityCardType, DoHCategory, DoHEvent, DoHTerrain } from "src/common/types/CatanCardTypes"
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, capitalize } from "@mui/material"
import { FilterList } from "@mui/icons-material"
import { ChangeEvent, useState } from "react"

const CardList: React.FC = () => {
    const [cardList, setCardList] = useState<Array<DawnOfHumanityCardType>>(DoHCommon)
    const [unfilteredCardList, setUnfilteredCardList] = useState<Array<DawnOfHumanityCardType>>(DoHCommon)
    const [sortCriteria, setSortCriteria] = useState("alphabetical")
    const [filterOpen, setFilterOpen] = useState(false)

    let filterCriteria: Array<string> = []

    const searchCards = (e: ChangeEvent<HTMLInputElement>) => {
        const search: string = e.target.value.toLowerCase()
        let filteredCardList: Array<DawnOfHumanityCardType> = []
        
        if (search && search !== '') { 
            DoHCommon.forEach((card: DawnOfHumanityCardType) => {
                if (JSON.stringify(card).toLowerCase().includes(search)) filteredCardList.push(card)
            })
        } else filteredCardList = DoHCommon

        setUnfilteredCardList(filteredCardList)
        filterCards(filteredCardList)
    }

    const filterCards = (e: ChangeEvent<HTMLInputElement> | Array<DawnOfHumanityCardType>) => {
        let filteredCardList: Array<DawnOfHumanityCardType> = []

        if (!Array.isArray(e)) {
            filteredCardList = unfilteredCardList

            const isSelected = typeof e === "string" ? false : e.target.checked
            const filter: string = typeof e === "string" ? e : e.target.value

            if (isSelected && !filterCriteria.includes(filter)) filterCriteria.push(filter)
            else if (filterCriteria.length === 1) filterCriteria = []
            else {
                const index = filterCriteria.findIndex((activeFilter: string) => activeFilter === filter)
                if (index) filterCriteria.splice(index, 1)
            }
        } else filteredCardList = e

        if (filterCriteria.length > 0) {
            filteredCardList = filteredCardList.filter((card: DawnOfHumanityCardType) => {
                const cardString = JSON.stringify(card).replace(/[^A-Z0-9]/ig, '').toLowerCase()
                let matchesFilter: boolean = false
    
                filterCriteria.forEach((activeFilter: string) => {
                    if (cardString.includes(activeFilter)) matchesFilter = true
                })
    
                return matchesFilter
            })
        }

        sortCards(filteredCardList)
    }

    const sortCards = (e: ChangeEvent<HTMLInputElement> | Array<DawnOfHumanityCardType>) => {
        const sort: string = Array.isArray(e) ? sortCriteria : e.target.value
        let sortedCardList: Array<DawnOfHumanityCardType> = Array.isArray(e) ? e : unfilteredCardList

        if (sort === "category") {
            sortedCardList.sort((n1: DawnOfHumanityCardType, n2: DawnOfHumanityCardType) => {
                let cardA = (n1.category + n1.name).replace(/[^A-Z0-9]/ig, '').toLowerCase()
                let cardB = (n2.category + n2.name).replace(/[^A-Z0-9]/ig, '').toLowerCase()
                return cardA < cardB ? -1 : 1
            })
        } else if (sort === "event") {
            sortedCardList.sort((n1: DawnOfHumanityCardType, n2: DawnOfHumanityCardType) => {
                const eventA = n1.event ? n1.event.toLowerCase() : "\u03A9"
                const eventB = n2.event ? n2.event.toLowerCase() : "\u03A9"
                let cardA = eventA + n1.name.replace(/[^A-Z0-9]/ig, '').toLowerCase()
                let cardB = eventB + n2.name.replace(/[^A-Z0-9]/ig, '').toLowerCase()

                return cardA < cardB ? -1 : 1
            })        
        } else if (sort === "alphabetical") sortedCardList.sort((n1: DawnOfHumanityCardType, n2: DawnOfHumanityCardType) => {
            let cardA = n1.name.replace(/[^A-Z0-9]/ig, '').toLowerCase()
            let cardB = n2.name.replace(/[^A-Z0-9]/ig, '').toLowerCase()

            return cardA < cardB ? -1 : 1  
        })

        setSortCriteria(sort)
        setCardList(sortedCardList)
    }

    const printCard = (card: DawnOfHumanityCardType): React.ReactNode => {
        return (
            <CardListStyles.CardContainer key={`card-${card.name}`}>
                <Typography variant="h1">
                    {card.name}
                </Typography>
                <CardListStyles.FlavorTextContainer>
                    <Typography variant="h4">
                        {capitalize(card.category)}
                        {card.event && (
                            <em>&nbsp;&#8212;&nbsp;{capitalize(card.event)}</em>
                        )}
                    </Typography>
                </CardListStyles.FlavorTextContainer>
                <CardListStyles.FlavorTextContainer>
                    <Typography variant="h2">
                        {card.flavorText}
                    </Typography>
                </CardListStyles.FlavorTextContainer>
                <Typography variant="h2">
                    {card.effectText}
                </Typography>     
            </CardListStyles.CardContainer>
        )
    }

    return (
        <CardListStyles.MainContainer>
            <CardListStyles.SearchContainer>
                <CardListStyles.SearchBar onChange={searchCards} variant="outlined" placeholder="Search cards..."/>
                <CardListStyles.FilterButton onClick={() => setFilterOpen(!filterOpen)} content="Filter & Sort" type="secondary" icon={FilterList} iconPosition="start"/>
            </CardListStyles.SearchContainer>
            <CardListStyles.FilterContainer open={filterOpen}>
                <CardListStyles.Filter>
                    <CardListStyles.FilterRow>
                        <FormLabel>Category:</FormLabel>
                        <CardListStyles.FilterSelections>
                            {DoHCategory.map((category: string) => 
                                <FormControlLabel key={`label-category-${category}`} value={`category${category}`} control={<Checkbox onChange={filterCards} />} label={capitalize(category)}/>
                            )}
                        </CardListStyles.FilterSelections>
                    </CardListStyles.FilterRow>
                    <CardListStyles.FilterRow>
                        <FormLabel>Event:</FormLabel>
                        <CardListStyles.FilterSelections>
                            {DoHEvent.map((event: string) => 
                                <FormControlLabel key={`label-event-${event}`}  value={`event${event}`} control={<Checkbox onChange={filterCards} />} label={capitalize(event)}/>
                            )}
                        </CardListStyles.FilterSelections>
                    </CardListStyles.FilterRow>
                    <CardListStyles.FilterRow>
                        <FormLabel>Terrain:</FormLabel>
                        <CardListStyles.FilterSelections>
                            {DoHTerrain.map((terrain: string) => 
                                <FormControlLabel key={`label-terrain-${terrain}`}  value={`terrain${terrain}`} control={<Checkbox onChange={filterCards} />} label={capitalize(terrain)}/>
                            )}
                        </CardListStyles.FilterSelections>
                    </CardListStyles.FilterRow>
                    <FormControl>
                        <FormLabel>Sort By:</FormLabel>
                        <RadioGroup value={sortCriteria} onChange={sortCards}>
                            <FormControlLabel value="alphabetical" control={<Radio />} label="Alphabetical"/>
                            <FormControlLabel value="event" control={<Radio />} label="Event"/>
                            <FormControlLabel value="category" control={<Radio />} label="Category"/>
                        </RadioGroup>
                    </FormControl>
                </CardListStyles.Filter>
            </CardListStyles.FilterContainer>
            <CardListStyles.CardListContainer onClick={() => setFilterOpen(false)}>
                {cardList && cardList.length !== 0 ? cardList.map((card: DawnOfHumanityCardType) => printCard(card)) : <Typography>No cards found.</Typography>}  
            </CardListStyles.CardListContainer> 
        </CardListStyles.MainContainer>
    )
}
export default CardList