import { styled } from "@mui/system"

const HomeComponentContainer = styled('div')(
    ({theme}) => ({
        background: theme.palette.background.default
    }
))

const HomeStyles = {
    HomeComponentContainer
}
export default HomeStyles