import { IconButton, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/store"
import { setNotificationState } from "src/store/ui"
import CloseIcon from "@mui/icons-material/Close"
import NotificationBarStyles from "./NotificationBar.styles"

const NotificationBar: React.FC = () => {
  const { anchorOrigin, message, autohide, onClose } = useSelector(
    (state: RootState) => state.notificationBarState
  )
  const dispatch = useDispatch()
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    setShowToast(message !== "")
  }, [message])

  const handleClose = () => {
    if (onClose) onClose()
		dispatch(
			setNotificationState({
				message: ""
			})
		)
  }

  return (
    <NotificationBarStyles.NordsToast
      anchorOrigin={anchorOrigin}
      autoHideDuration={autohide ? 2000 : undefined}
      open={showToast}
      onClose={handleClose}
      message={message}
      key={`notification-${message}`}
      action={
        <IconButton
          aria-label="close"
          color="error"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}
export default NotificationBar
