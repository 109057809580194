import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NotificationStateType } from "src/common/resources/Common"

type UIState = {
  sideNav: {
    isOpen: boolean
  }
  windowDimensions: {
    width: number
    height: number
  }
  notificationBarState: NotificationStateType
}

const initialState: UIState = {
  sideNav: {
    isOpen: false
  },
  windowDimensions: {
    width: 0,
    height: 0
  },
  notificationBarState: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    open: false,
    message: "",
    autohide: true
  }
}

const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSideNav(state) {
      state.sideNav.isOpen = !state.sideNav.isOpen
    },
    setWindowDimensions(state, { payload }: PayloadAction<{width: number, height: number}>) {
      state.windowDimensions.width = payload.width
      state.windowDimensions.height = payload.height
    },
    setNotificationState(state, { payload }: PayloadAction<NotificationStateType>) {
      state.notificationBarState = {
        anchorOrigin: {
          vertical: payload?.anchorOrigin?.vertical ?? "top",
          horizontal: payload?.anchorOrigin?.horizontal ?? "center"
        },
        autohide: payload.autohide,
        open: payload.open,
        message: payload.message,
        onClose: payload.onClose, 
      }
    }
  }
})

export const { toggleSideNav, setWindowDimensions, setNotificationState } = slice.actions

export default slice.reducer
