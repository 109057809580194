import { styled } from "@mui/material/styles"
import { SIDE_NAV_WIDTH } from "../AppContainer/AppContainer.styles"

const MainContainer = styled('div')(
    ({theme}) => ({
        background: theme.palette.background.default,
        width: SIDE_NAV_WIDTH,
        height: "100%"
    })
)

const DrawerNavigationStyles = {
    MainContainer
}
export default DrawerNavigationStyles
 
