import { Menu } from "@mui/icons-material"
import { AppBar, IconButton, Typography } from "@mui/material"
import { Outlet } from "react-router-dom"
import DrawerNavigation from "../DrawerNavigation"
import AppContainerStyles from "./AppContainer.styles"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/store"
import { toggleSideNav, setWindowDimensions } from "src/store/ui"
import { useEffect } from "react"
import NotificationBar from "../NotificationBar"

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const AppContainer: React.FC = () => {
  const {
    sideNav: { isOpen },
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const toggleDrawer = () => {
    dispatch(toggleSideNav())
  }

  useEffect(() => {
    handleResize()
  })

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  const handleResize = () => {
    dispatch(setWindowDimensions(getWindowDimensions()))
  }

  return (
    <AppContainerStyles.AppContainer>
      <NotificationBar />
      <AppBar>
        <AppContainerStyles.HeaderBar open={isOpen}>
          <IconButton onClick={toggleDrawer} size="large" color="inherit">
            <Menu />
          </IconButton>
          <AppContainerStyles.HeaderTitle>
            <Typography variant="subtitle2">Nordsworth.gg</Typography>
          </AppContainerStyles.HeaderTitle>
        </AppContainerStyles.HeaderBar>
      </AppBar>
      <AppContainerStyles.NavDrawer variant="persistent" open={isOpen}>
        <DrawerNavigation />
      </AppContainerStyles.NavDrawer>
      <AppContainerStyles.ComponentOutlet
        onClick={isOpen ? toggleDrawer : () => {}}
        open={isOpen}
      >
        <Outlet />
      </AppContainerStyles.ComponentOutlet>
    </AppContainerStyles.AppContainer>
  )
}
export default AppContainer
