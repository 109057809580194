import Breadcrumbs from "src/components/Breadcrumbs"

const SRStatCalculator: React.FC = () => {
    const breadcrumbs = ['Honkai: Star Rail', 'Stat Calculator']

    return (
        <>
            <Breadcrumbs data={breadcrumbs}/>
        </>
    )
}
export default SRStatCalculator