import { SvgIconTypeMap, Typography } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import NordsButtonStyles from "./NordsButton.styles"
import React from "react"

type NordsButtonProps = {
    type: "primary" | "secondary" | "text"
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
    iconPosition?: "start" | "end"
    content: string
    onClick?: () => void
    disabled?: boolean
}

const NordsButton: React.FC<NordsButtonProps> = ({
    type,
    icon: Icon,
    iconPosition,
    content,
    onClick,
    disabled
}) => {
    const NordsButton = type === "primary" ? NordsButtonStyles.PrimaryNordsButton : NordsButtonStyles.SecondaryNordsButton

    const handleClick = (e: any) => {
        if (onClick) onClick()
    }

    return (
        <>
            <NordsButton variant={type === "primary" ? "contained" : "outlined"} onClick={handleClick} disabled={disabled}>
                {(Icon && iconPosition !== "end") && (
                    <Icon height="15px"/>
                )}
                <Typography variant="h5">{content}</Typography>
                {(Icon && iconPosition === "end") && (
                    <Icon/>
                )}
            </NordsButton>
        </>
    )
}
export default NordsButton