import { List, Typography } from "@mui/material"
import { RouteType } from "src/routes/config/RoutesConfig"
import { useState } from "react"
import NavItem from "../NavItem"
import { Box } from "@mui/system"
import NavItemStyles from "../NavItem/NavItem.styles"

type navItemProps = {
    route: RouteType
}

const CollapsibleNavItem: React.FC<navItemProps> = ({ route }) => {
    const [ open, setOpen ] = useState(false)

    const createNavItem = (route: RouteType): React.ReactNode => {
        if (route.childs && route.childs.length > 0) return <CollapsibleNavItem key={`collapsible-nav-item-${route.id}`} route={route}/>
        else return <NavItem key={`nav-item-${route.id}`} route={route} isSub={true}/>
    }

    const constructList = () => {
        if (route.childs && route.childs.length > 0) return route.childs.map((route: RouteType) => createNavItem(route))
        else return
    }

    return (
        <Box id={`list-item-${route.id}`} key={`nav-item-${route.id}`}>
            <NavItemStyles.CollapsibleNavItem onClick={() => setOpen(!open)}>
                <NavItemStyles.TitleContainer>
                    <Typography id={`list-item-text-${route.id}`} variant="h3">{route.title}</Typography>
                </NavItemStyles.TitleContainer>
                <NavItemStyles.IconContainer>
                    <NavItemStyles.ExpandIcon open={open}/>
                </NavItemStyles.IconContainer>
            </NavItemStyles.CollapsibleNavItem>
            <NavItemStyles.NavCollapse in={open} key={`collapse-${route.id}`}>
                <List key={`list-${route.id}`}>
                    {constructList()}
                </List>    
            </NavItemStyles.NavCollapse>
        </Box>
    )
}
export default CollapsibleNavItem