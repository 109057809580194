import Breadcrumbs from "src/components/Breadcrumbs"

const SRCharacters: React.FC = () => {
    const breadcrumbs = ['Honkai: Star Rail', 'Characters']

    return (
        <>
            <Breadcrumbs data={breadcrumbs}/>
            Hello, welcome to Chili's
        </>
    )
}
export default SRCharacters