import { configureStore } from "@reduxjs/toolkit"
import slice from "./ui/slice"

const store = configureStore({
  reducer: slice,
  devTools: process.env.NODE_ENV !== "production",
})

export type RootState = ReturnType<typeof slice>
export type AppDispatch = typeof store.dispatch

export default store
