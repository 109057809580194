import { Typography } from "@mui/material"

import HomeStyles from "./Home.styles"
import Breadcrumbs from "src/components/Breadcrumbs"

const Home: React.FC = () => {
    const breadcrumbs = "Home"
    return (
        <HomeStyles.HomeComponentContainer>
            <Breadcrumbs data={breadcrumbs}/>
            <Typography variant="h1">
                Welcome to Nordsworth.gg
            </Typography>
        </HomeStyles.HomeComponentContainer>
    )
}
export default Home