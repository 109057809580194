import { ChevronRight } from "@mui/icons-material"
import { Collapse, ListItem } from "@mui/material"
import { styled } from "@mui/material/styles"

const NavItem = styled(ListItem)(
    ({ theme }) => ({
        borderRadius: "10px 0px 0px 10px",
        cursor: "pointer",
        minHeight: 40,
        transition: theme.transitions.create('background', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest,
        }),
        "&:hover": {
            background: theme.palette.secondary.light
        }
    })
)

const CollapsibleNavItem = styled(NavItem)(
    ({ theme }) => ({
        display: "block",
    })
)

const NavCollapse = styled(Collapse)(
    ({ theme }) => ({
        marginLeft: 10
    })
)

const TitleContainer = styled('div')(
    ({ theme }) => ({
        float: "left"
    })
)

const IconContainer = styled('div')(
    ({ theme }) => ({
        float: "right"
    })
)

const ExpandIcon = styled(ChevronRight, { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean}>(
    ({ theme, open }) => ({
        transform: open ? "rotate(90deg)" : '',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest,
        }),
    })
)

const NavItemStyles = {
    NavItem, CollapsibleNavItem, TitleContainer, IconContainer, NavCollapse, ExpandIcon
}
export default NavItemStyles
