import { Drawer, Toolbar } from "@mui/material"
import { styled } from "@mui/material/styles"

export const TOP_NAV_HEIGHT = 48
export const SIDE_NAV_WIDTH = 360

const AppContainer = styled('div')(
    ({ theme }) => ({
        overflowY: "auto",
        background: theme.palette.background.default,
        height: "100vh",
        width: "100vw",
        display: "flex"
    })
)

const ComponentOutlet = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean}>(
    ({ theme, open }) => ({
        position: "relative",
        top: TOP_NAV_HEIGHT,
        height: `calc(100% - ${TOP_NAV_HEIGHT}px - 40px)`,
        width: "100%",
        overflow: "auto",
        margin: "1.5vw",
        marginLeft: open ? SIDE_NAV_WIDTH + 20 : 20,
        transition: open ? 
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }) :
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
    })
)

const HeaderTitle = styled('div')(
    ({ theme }) => ({
        paddingLeft: 24
    })
)

const HeaderBar = styled(Toolbar, { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean}>(
    ({ theme, open }) => ({
        background: theme.palette.secondary.dark,
        marginLeft: open ? SIDE_NAV_WIDTH : 0,
        transition: open ? 
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }) :
            theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
    })
)

const NavDrawer = styled(Drawer)(
    ({ theme }) => ({
        backgroundColor: theme.palette.primary.light
    })
)

const AppContainerStyles = {
    AppContainer, 
    ComponentOutlet, 
    HeaderTitle, 
    HeaderBar, 
    NavDrawer
}
export default AppContainerStyles