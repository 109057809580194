import { styled } from "@mui/system"

const Breadcrumb = styled('div')(
    ({theme}) => ({
        marginBottom: 20,
        minHeight: 24
    })
)

const BreadcrumbNode = styled('div')(
    ({theme}) => ({
        display: "flex",
        alignItems: "center"
    })
)

const BreadcrumbsStyles = {
    Breadcrumb, BreadcrumbNode
}
export default BreadcrumbsStyles