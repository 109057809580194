import DrawerNavigationStyles from "./DrawerNavigation.styles"
import { RouteType, RoutesConfig } from "src/routes/config/RoutesConfig"
import NavItem from "../NavItem"
import { List } from "@mui/material"
import CollapsibleNavItem from "../CollapsibleNavItem/CollapsibleNavItem"

const DrawerNavigation: React.FC = () => {
    const createNavList = (routes: Array<RouteType>): React.ReactNode => {
        if (!routes || routes.length === 0) return 
        return routes.map((route: RouteType) => createNavItem(route))
    }

    const createNavItem = (route: RouteType): React.ReactNode => {
        if (!route.showInSideNavigation && route.childs && route.childs.length > 0) return createNavList(route.childs)
        if (!route.showInSideNavigation) return
        else if (route.showInSideNavigation && route.childs && route.childs.length !== 0) return <CollapsibleNavItem key={`collapsible-nav-item-${route.id}`} route={route}/>
        else return <NavItem key={`nav-item-${route.id}`} route={route}/>
    }

    return (
        <DrawerNavigationStyles.MainContainer>
            <List>
                {createNavList(RoutesConfig)}
            </List>
        </DrawerNavigationStyles.MainContainer>     
    )
}
export default DrawerNavigation