export type CatanCardType = {
    id?: string
    name: string
    category: string,
    event?: string,
    effectText: string
    flavorText: string
    game?: string,
    terrain?: string
}

export type DawnOfHumanityCardType = CatanCardType & {
    category: DoHCategoryType
    event?: DoHEventType
    terrain?: DoHTerrainType
}

export type RarityType = {
    name: string,
    type: string,
    quantity: number
}

export type DoHCategoryType = "occurence" | "resource" | "victory" | "progress" | "encounter"
export const DoHCategory = ["occurence", "resource", "victory", "progress", "encounter"]
export type DoHEventType = "fortune" | "misfortune" | "challenge"
export const DoHEvent = ["fortune", "misfortune", "challenge"]
export type DoHTerrainType = "forest" | "plains" | "pastures" | "mountains"
export const DoHTerrain = ["forest", "plains", "pastures", "mountains"]
