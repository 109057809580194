import { Snackbar } from "@mui/material"
import { styled } from "@mui/system"

const NordsToast = styled(Snackbar)(
  ({ theme,  }) => ({
    background: theme.palette.secondary
  }))

const NotificationBarStyles = {
  NordsToast,
}

export default NotificationBarStyles
