import { FormGroup, TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
import NordsButton from "src/components/NordsButton/NordsButton"

const MainContainer = styled('div')(
    ({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        padding: 20,
        border: `1px solid ${theme.palette.secondary.light}`,
        background: theme.palette.secondary.dark,
        borderRadius: 25,
        height: "calc(100% - 40px)"
    })
)

const FilterContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean}>(
    ({ theme, open }) => ({
        minHeight: open ? 300 : 0,
        maxHeight: open ? 300 : 0,
        transition: theme.transitions.create(['min-height', 'max-height'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest
        }),
        overflow: "hidden",
        marginBottom: open ? 20 : 0
    })
)

const FilterButton = styled(NordsButton)(
    ({ theme }) => ({
        minWidth: 170
    })
)

const Filter = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean}>(
    ({ theme, open }) => ({
        display: "flex",
        background: theme.palette.secondary.main,
        borderRadius: 25,
        padding: 20,
        border: `1px solid ${theme.palette.secondary.light}`,
    })
)

const FilterRow = styled('div')(
    ({ theme }) => ({
        display: "flex",
        flexDirection: "column"
    })
)

const FilterSelections = styled(FormGroup)(
    ({ theme }) => ({
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr",
        gridAutoFlow: "row"
    })
)

const SearchContainer = styled('div')(
    ({ theme }) => ({
        display: "flex"
    })
)

const SearchBar = styled(TextField)(
    ({ theme }) => ({
        width: "calc(100% - 190px)",
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: 10,
        background: theme.palette.secondary.main,
        marginBottom: 20,
        marginRight: 20,
        "& input" : {
            padding: "6px 10px",
        }
    })
)

const CardListContainer = styled('div')(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.secondary.light}`,
        background: theme.palette.secondary.main,
        rowGap: 20,
        columnGap: 20,
        padding: 20,
        borderRadius: 25,
        overflow: "scroll",
        display: "flex",
        flexDirection: "row",
        flexFlow: "row wrap"
    })
)

const CardContainer = styled('div')(
    ({ theme }) => ({
        background: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.primary.dark}`,
        borderRadius: 25,
        padding: 20,
        whiteSpace: "pre-wrap",
        maxWidth: 385,
        height: 600
    })
)

const FlavorTextContainer = styled('div')(
    ({ theme }) => ({
        marginBottom: "1.2rem"
    })
)

const CardListStyles = {
    MainContainer, FilterContainer, Filter, FilterButton, FilterRow, FilterSelections, SearchContainer, SearchBar , CardListContainer, CardContainer, FlavorTextContainer
}
export default CardListStyles