import { Typography } from "@mui/material"
import { RouteType } from "src/routes/config/RoutesConfig"
import { useNavigate } from "react-router-dom"
import NavItemStyles from "./NavItem.styles"
import { useDispatch } from "react-redux"
import { toggleSideNav } from "src/store/ui"

type navItemProps = {
    route: RouteType
    onClick?: () => void
    isSub?: boolean
}

const NavItem: React.FC<navItemProps> = ({ route, onClick, isSub }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id, icon: Icon, path, title } = route

    const NavItem = NavItemStyles.NavItem

    const changeRoute = () => {
       if (path) navigate(path.absolutePath)
       dispatch(toggleSideNav())
    }

    return (
        <NavItem onClick={onClick ? onClick : () => (path && path) ? changeRoute() : {}} id={`list-item-${id}`} key={id}>
            {Icon && <Icon/>}
            <Typography id={`list-item-text-${id}`} variant={isSub ? "h4" : "h3"}>{title}</Typography>
        </NavItem>
    )
}
export default NavItem