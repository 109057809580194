import { createTheme } from "@mui/material"
import type {} from '@mui/lab/themeAugmentation'
import { fontWeight } from "@mui/system"

const palette = {
  background: {
    default: "#22223B"
  },
  text: {
    primary: "#FFF"
  },
  primary: {
    main: "#CAE9FF",
    dark: "#77879F",
    light: "#E8F6FF",
    contrastText: "#0D0D17"
  },
  secondary: {
    main: "#22223B",
    dark: "#19192D",
    light: "#2C2D47",
    contrastText: "#FFF"
  },
  // primary: {
  //   dark: "#19192D",
  //   main: "#22223B",
  //   light: "#2C2D47",
  //   contrastText: "#FFF"
  // },
  // secondary: {
  //   dark: "#363852",
  //   main: "#CAE9FF",
  //   light: "#726D81",
  //   contrastText: "#CAE9FF"
  //   // dark: "#363852",
  //   // main: "#4A4E69",
  //   // light: "#726D81",
  //   // contrastText: "#CAE9FF"
  // },
}

const theme = createTheme({
  palette: palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
       "@font-face": {
          fontFamily: "Lato",
          fontSize: "1rem",
          color: "#FFF"
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: "#FFF"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#4A4E69"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#FFF"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#19192D"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid #4A4E69`
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: palette.primary.light,
          fontSize: "1.2rem",
          fontWeight: 600,
          fontFamily: "Lato",
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 20,
          height: "100%"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.main
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          background: palette.secondary.light,
          border: `1px solid ${palette.primary.main}`
        },
        message: {
          color: palette.primary.main,
          fontWeight: 600
        }
      }
    }
  },
  mixins: {
    toolbar: {
      minHeight: 46,
      maxHeight: 48,
      height: 48,
    },
  },
  typography: {
    fontFamily: "Lato",
    fontSize: 16,
    h1: {
      fontSize: "1.4rem",
      color: "#FFF",
      fontFamily: "Lato",
      fontWeight: 800,
      marginBottom: 20
    },
    h2: {
      fontSize: "1.3rem",
      color: "#FFF",
      fontFamily: "Lato",
    },
    h3: {
      fontSize: "1.2rem",
      color: "#FFF",
      fontFamily: "Lato",
      fontWeight: 900
    },
    h4: {
      fontSize: "1.2rem",
      color: "#FFF",
      fontFamily: "Lato",
    },
    h5: {
      fontSize: "1.1rem",
      fontWeight: 600,
      fontFamily: "Lato",
    },
    subtitle1: {
      fontSize: 13,
      color: "#FFF",
      fontFamily: "Lato",
    },
    subtitle2: {
      fontSize: 22,
      color: "#FFF",
      fontFamily: "Fira Sans Extra Condensed",
      fontWeight: 500,
      fontStyle: "italic"
    }
  }
})
export default theme
