import { DawnOfHumanityCardType } from "../types/CatanCardTypes"

export const DoHCommon: Array<DawnOfHumanityCardType> = [
    {
        name: "Oh Lord Jesus, it's a Wildfire",
        category: "occurence",
        event: "misfortune",
        terrain: "forest",
        effectText: "Lose 1 forest campsite.",
        flavorText: "The winds began to pick up in the forest. The hot ash from the campfire spread, causing flames to ignite from all over the forest. ",
    },
    {
        name: "Dry Dry Desert",  
        category: "occurence",
        event: "misfortune",
        terrain: "plains",
        effectText: "Lose 1 plains campsite.",
        flavorText: "In the heart of the parched expanse, whispers fade, and oases vanish beneath shifting sands."
    },
    {
        name: "Nightshade Outbreak",
        category: "occurence",
        event: "misfortune",
        terrain: "pastures",
        effectText: "Lose 1 pasture campsite.",
        flavorText: "An animal is laying on the ground, passed away. There seems to be something in its mouth: a nightshade. The nightshades have begun to spread aronud the pastures."
    },
    {
        name: "Beware the trolls!",
        category: "occurence",
        event: "misfortune",
        terrain: "mountains",
        effectText: "Move 1 mountain campsite to any adjacent campsite marker.",
        flavorText: "As the sun dips below the peaks, travelers heed the whispered warnings: 'Beware the trolls, for where they tread, mountains shift and paths vanish."
    },
    {
        name: "Hide 'n Seek",
        category: "occurence",
        event: "fortune",
        terrain: "forest",
        effectText: "Gain 1 hide for every forest campsite you have.",
        flavorText: "In the heart of the wilds, where nature's chorus sings loudest, one must tread lightly. For in the realm where beasts roam, every step may yield unexpected bounty.",
    },
    {
        name: "It's not a hallucination...",
        category: "occurence",
        event: "fortune",
        terrain: "plains",
        effectText: "Gain 1 bone for every plains campsite you have.",
        flavorText: "Amidst the shimmering waves of heat, a mirage takes form. But as the sands shift, bones emerge like ancient treasures."
    },
    {
        name: "Cowabundance",
        category: "occurence",
        event: "fortune",
        terrain: "pastures",
        effectText: "Gain 1 meat for every meat you own. If you own no meat, gain 3 meat.",
        flavorText: "In the land of milk and honey, the pastures overflow with more than just dairy dreams."
    },
    {
        name: "Never back down, never what?",
        category: "occurence",
        event: "fortune",
        terrain: "mountains",
        effectText: "Gain 2 flint for every active explorer.",
        flavorText: "Never give up! After mining for several hours, explorers finally arrive at what appears to be a flint deposit."
    },
    {
        name: "World War 0.5",
        category: "occurence",
        event: "challenge",
        effectText: "Declare war on another player. Roll dice to compete for a higher number. If you win, take one random resource. If you lose, lose a campsite outside of Africa and replace it with the opponent campsite marker.",
        flavorText: "Members of a neighboring tribe were caught gathering resources on your land."
    },
    {
        name: "Alien Enlightenment",
        category: "victory",
        event: "fortune",
        effectText: "Increase the progress level of construction or clothing to max. If both traits are already at max level, gain 1 of each resource.",
        flavorText: "An explorer was mysteriously abdusted by a group of unidentified creatures. After being unconscious for several hours, the explorer wakes up bestowed with the knowledge of a previous civilation."
    },
    {
        name: "Bone-Gnawing Bounty",
        category: "resource",
        event: "fortune",
        effectText: "Gain 1 bone.",
        flavorText: "In the depths of exploration, an explorer unearths a bone, a relic of forgotten beasts. A tantalizing taste of adventure awaits, with just the right amount of prehistoric flavor!"
    },
    {
        name: "Fur-tuitous Findings",
        category: "resource",
        event: "fortune",
        effectText: "Gain 1 hide.",
        flavorText: "Amidst the foliage, an explorer stumbled upon a fur-tuitous find, as if the forest itself was laying out the red carpet... or should we say, the fur carpet?"
    },
    {
        name: "Flint-tastic Discovery",
        category: "resource",
        event: "fortune",
        effectText: "Gain 1 flint.",
        flavorText: "Scaling the rugged cliffs, an explorer's keen eye caught sight of a glimmering pile of flint, nestled within a rocky outcrop."
    },
    {
        name: "Meat-y Miracle",
        category: "resource",
        event: "fortune",
        effectText: "Gain 1 meat.",
        flavorText: "In the heart of the untamed wilderness, an explorer's path intersected with a bustling hunting ground, where a fresh catch of meat lay waiting to be claimed."
    },
    {
        name: "Bison Bonanza",
        category: "resource",
        event: "fortune", 
        effectText: "All players gain 1 meat.",
        flavorText:"A thundering herd of bison charges through the pastures, presenting a golden opportunity for all explorers to join in the chase and secure a hearty supply of meat for their feasts."
    },
    {
        name: "Coyote Bounty",
        category: "resource",
        event: "fortune",
        effectText: "All players gain 1 bone.",
        flavorText: "Discovering a coyote den in the desert plains, explorers stumble upon a hidden cache of bones, scavenged from the remains of past hunts. With eager hands, they collect the bones, ready to fashion them into tools and artifacts to aid in their survival."
    },
    {
        name: "Flint Frolic",
        category: "resource",
        event: "fortune",
        effectText: "All players gain 1 flint.",
        flavorText: "Amidst the rugged peaks of the mountains, a sparkling vein of flint catches the eye of all explorers, leading to a spirited competition as they race to extract the valuable resource and stockpile it for future projects."
    },
    {
        name: "Hide-and-Seek Triumph",
        category: "resource",
        event: "fortune",
        effectText: "All players gain 1 hide.",
        flavorText: "A successful ambush of a herd of elusive deer in the wilderness provides all explorers with a wealth of hides, as they revel in their triumph and plan the crafting of new garments and shelters from the spoils of their hunt."
    },
    {
        name: "Berry Bonanza",
        category: "resource",
        event: "fortune",
        effectText: "All players may move one intersection.",
        flavorText: "The season is in full bloom, and the land is flourishing with an abundance of ripe berries. As explorers indulge in the sweet, energizing fruit, they feel invigorated and ready to venture further, gaining an extra boost of energy to explore new territories."
    },
    {
        name: "Bone-dry Bereavement",
        category: "resource",
        event: "misfortune",
        effectText: "Discard 1 bone.",
        flavorText: "In the heart of the wilderness, disaster strikes as an explorer finds themselves bone-dry, robbed of their precious bone. Was it the work of mischievous critters?"
    },
    {
        name: "Hideaway Heist",
        category: "resource",
        event: "misfortune",
        effectText: "Discard 1 hide.",
        flavorText: "As night falls in the forest, a campsite is raided, leaving them stripped of their hide."
    },
    {
        name: "Meatless Misfortune",
        category: "resource",
        event: "misfortune",
        effectText: "Discard 1 meat.",
        flavorText: "A campsites provisions take a turn for the worse as their meat spoils prematurely, leaving them with a foul odor and a sinking feeling in their stomachs."
    },
    {
        name: "Flint Fiasco",
        category: "resource",
        event: "misfortune",
        effectText: "Lose 1 flint.",
        flavorText: "As an explorer seeks out flint amidst the terrain, their search yields an unexpected twist – what they thought was flint turns out to be a deceptive rock of a different kind."
    },
    {
        name: "Caffeinated Clarity",
        category: "resource",
        event: "fortune",
        effectText: "Move an explorer 1-3 intersections. If you do not have an explorer, gain 1 meat and 1 hide.",
        flavorText: "Amidst the dense foliage, an explorer stumbles upon a peculiar plant with mysterious properties. After a hesitant sip, they're suddenly infused with a random burst of energy."
    },
    {
        name: "Wanderer's Wayback",
        category: "resource",
        event: "misfortune",
        effectText: "Return an explorer back to their starting position.",
        flavorText: "In the labyrinth of the wilderness, an explorer's path takes a bewildering turn. Despite their best efforts to navigate, they find themselves inexplicably back at the campsite."
    },
    {
        name: "Pasture Stampede",
        category: "resource",
        event: "misfortune",
        effectText: "All players lose 1 meat.",
        flavorText: "A massive herd of migrating beasts tramples through the pastures, devouring vegetation and causing a shortage of game for all explorers, making it difficult to secure meat for sustenance."
    },
    {
        name: "Desert Sandstorm",
        category: "resource",
        event: "misfortune",
        effectText: "All players lose 1 bone.",
        flavorText: "A fierce sandstorm sweeps through the desolate plains, burying bone deposits beneath shifting sands and hindering all explorers in their search for bones to craft tools and structures."
    },
    {
        name: "Heavy Rainfall",
        category: "resource",
        event: "misfortune",
        effectText: "All players lose 1 hide.",
        flavorText: "Torrential rains sweep across the land, saturating the ground and making it difficult to find and preserve hides for crafting."
    },
    {
        name: "Mountain Frostbite",
        category: "resource",
        event: "misfortune",
        effectText: "All players lose 1 flint.",
        flavorText: "Biting cold grips the mountain peaks, causing frost to form on exposed surfaces and making it difficult for all explorers to extract flint from the frozen terrain."
    },
    {
        name: "The Lone Wolf",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: The wolf attacks fiercely, overpowering the explorer. Lose 1 explorer.\n\n2-5: The explorer manages to distract the wolf and escapes. Pay 1 resource.\n\n6: The explorer bravely confronts the wolf, driving it away and securing a meal. Gain 1 meat.",
        flavorText: "As an explorer was crossing through the pastures, they stumbled upon a lone wolf."
    },
    {
        name: "The Ancient Boulder",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: As they attempt to move the boulder, it shifts unexpectedly, causing a rockslide. Lose 1 explorer.\n\n2-5: After much effort, the explorer manages to roll the boulder with the help of some resources. Pay 1 hide.\n\n6: After moving the boulder aside, the explorer discovers ancient markings on the boulder indicating a hidden cave. Gain 2 flint.",
        flavorText: "While traversing the rugged mountains, an explorer comes across an enormous boulder blocking your path."
    },
    {
        name: "Tribal Rivalry",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: The rival tribe proves too strong, forcing your explorers to retreat. Lose 1 explorer.\n\n2-5: After a fierce exchange of words and resources, your explorers negotiate a truce, but it comes at a cost. Pay 1 hide.\n\n6: Your explorers' skilled diplomacy and strategic alliances impress the rival tribe, leading to a peaceful integration. Gain 1 meat and 1 bone.",
        flavorText: "As your explorers expands its territory, you encounter a rival tribe blocking your path, challenging you for dominance."
    },
    {
        name: "Sabertooth's Ambush",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: Caught off guard! The sabertooth strikes swiftly, claiming an explorer as prey. Lose 1 explorer.\n\n2-5: Fend off the sabertooth's attack with makeshift weapons, but suffer injuries. Pay 1 flint.\n\n6: Subdue the sabertooth, securing valuable meat and its fearsome claws as trophies. Gain 1 meat and 1 bone.",
        flavorText: "While exploring the grasslands, an explorer encounters a lurking sabertooth tiger, its eyes gleaming with predatory intent."
    },
    {
        name: "Mammoth's Stampede",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: Trampled by the mammoth herd! An explorer is caught beneath the thundering hooves. Lose 1 explorer.\n\n2-5: Dodge the stampeding mammoths, but struggle to regain lost supplies. Pay 1 bone to replace lost resources.\n\n6: Successfully divert the mammoth herd, earning their respect and gathering resources from their abandoned grazing grounds. Gain 1 random resource card and 1 hide.",
        flavorText: "In the open plains, explorers witness a stampede of mammoths thundering towards them, their massive forms shaking the ground."

    },
    {
        name: "Poisoned Water Source",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: The poison takes its toll swiftly. Lose 1 explorer.\n\n2-5: Suffer from the effects of the poison, but manage to recover with herbal remedies. Pay 1 random resource.\n\n6: Discover a nearby freshwater spring, cleansing the group of the poison's effects. Move an explorer 1-3 intersections.",
        flavorText: "Explorers, parched from their journey, drink from a seemingly clear water source, only to realize too late that it's been tainted with poison."
    },
    {
        name: "Frostbitten Mountain Refuge",
        category: "encounter",
        effectText: "Roll the dice:\n\n0-1: The cold snap freezes the campsite's flint supply! Lose 1 flint.\n\n2-5: Endure the freezing temperatures, but suffer from frostbite. Pay 1 meat.\n\n6: Discover a hidden cavern beneath the mountain, offering shelter from the cold and revealing valuable resources. Gain 1 random resource card and 1 hide resource.",
        flavorText: "While resting at a mountain campsite, explorers are suddenly struck by a bone-chilling cold front, threatening their supplies and safety."
    },
    {
        name: "Traveling Merchant",
        category: "encounter",
        effectText: "Choose one option:\n\nOffer 1 meat: The traveler gladly accepts your offer, exchanging meat for a sturdy hide. Pay 1 meat and gain 1 hide.\n\nOffer 1 meat and 1 bone: Surprised by the offer, the traveler accepts the trade, offering a valuable flint in return. Pay 1 meat and 1 hide and gain 1 flint.\n\nAttempt to steal from the traveler: The traveler catches you in the act and flees, leaving behind his goods. Your attempt has strained relations with the traveler, who may seek retribution in the future. Gain 3 meat.",
        flavorText:  "As the sun sets, a traveler approaches your campsite, offering exotic goods from distant lands."
    },
    {
        name: "Vengeful Traveler",
        category: "encounter",
        effectText: "Choose one option:\n\nPay 3 meat: Despite your payment, the traveler insists that this is not their missing goods and proceeds to ransack the campsite, leaving it in ruins and devoid of resources. Lose 1 campsite and all resources.\n\nFight the bandits: In a fierce battle, you manage to fend off the bandits, but the conflict takes its toll. Lose 1 explorer.\n\nNegotiate with the traveler: Despite your attempts at negotiation, the traveler declines your offer and orders the bandits to pillage the campsite. While the campsite remains intact, all resources are stripped bare. Lose all resources.",
        flavorText: "As night descends, a traveler from a distant land arrives at your campsite, flanked by a band of menacing bandits. They demand retribution for an earlier encounter."
    },
    {
        name: "Flying Beings",
        category: "encounter",
        effectText: "Attack the flying beings: Despite the efforts, the flying beings remain unfazed and swiftly flee, saying \"they are not ready\". Nothing happens.\n\nConverse with the flying beings: An explorer attempts to make contact with the beings. They do not respond and immeditiately depart. Nothing happens.\n\nOffer all resources: In an act of reverence, an explorer offers all resources to the flying beings. To reward their devotion, they bestow upon the explorer an abundance of resources. All your resources are returned, and gain 1 of every resource.",
        flavorText: "As dawn breaks, a campsite witnesses the descent of ethereal beings with wings, radiating divine presence."
    },
    {
        name: "Threadvolution",
        category: "progress",
        event: "fortune",
        effectText: "Increase clothing progress by one level.",
        flavorText: "Stumbling upon a cache of attire, your explorers finds themselves in stitches over the sheer brilliance of the designs. Determined to thread the needle of progress, they embark on a seam-ingly impossible quest to unravel the mysteries of garment-making. "
    },
    {
        name: "Blueprint Breakthrough",
        category: "progress",
        event: "fortune",
        effectText: "While exploring the wilderness, your explorers uncover an unfamiliar structure. Intrigued by the architectural marvels depicted within, they set out to decipher the construction and incorporate them into their own building techniques.",
        flavorText: "Increase construction progress by one level."
    },
    {
        name: "Spice Odyssey",
        category: "progress",
        event: "fortune",
        effectText: "Increase food progress by one level.",
        flavorText: "During a foraging expedition, your explorers stumble upon a hidden grove bursting with exotic spices and herbs. Inspired by the tantalizing aromas and vibrant flavors, your explorers embark on a culinary adventure, experimenting with new seasonings and flavor combinations."
    },
    {
        name: "Beastly Epiphany",
        category: "progress",
        event: "fortune",
        effectText: "Increase hunting progress by one level.",
        flavorText: "While traversing the untamed wilderness, your hunters chance upon a majestic predator stalking its prey with unparalleled grace and cunning. Mesmerized by the creature's techniques, your explorers observe and learn from nature's masterful hunt, gaining invaluable insights into the art of the chase."
    },
    {
        name: "Threadbare Setback",
        category: "progress",
        event: "misfortune",
        effectText: "Decrease clothing progress by one level.",
        flavorText: "As your explorers weave through dense foliage, a sudden downpour drenches your campsite, ruining your meticulously crafted garments and forcing you to start anew with limited resources."
    },
    {
        name: "Architectural Mishap",
        category: "progress",
        event: "misfortune",
        effectText: "Decrease construction progress by one level.",
        flavorText: "As your explorers endeavor to erect a new structure, a critical flaw in the design is discovered, halting construction progress and necessitating costly revisions."
    },
    {
        name: "Famine's Grip",
        category: "progress",
        event: "misfortune",
        effectText: "Decrease food progress by one level.",
        flavorText: "A harsh drought sweeps across the land, decimating crops and depleting food reserves, leaving campsites devoid of food and explorers hungry and desperate"
    },
    {
        name: "Wild Gambit",
        category: "progress",
        event: "misfortune",
        effectText: "Decrease hunting progress by one level.",
        flavorText: "Seeking a new hunting ground, your explorers venture into uncharted territory, only to encounter fierce competition from rival predators and territorial disputes with indigenous wildlife."
    }
]

export const DeckBuilderIntro: string = "Enter the world of Catan: Dawn of Humankind and discover the thrilling addition of the Scenario Deck! We'll explore the essence of the Scenario Deck and how it enriches your gaming adventure. So gather around the tribal fire, and let's delve into the heart of this exciting expansion together!"
export const DeckBuilderDescription: Array<string> = [
    'Welcome, intrepid explorers, to the dawn of something truly extraordinary! In the illustrious world of Catan: Dawn of Humankind, where settlers roam and mammoths roam even more, we\'ve brewed up a concoction of excitement to make your journey even more thrilling. Enter, the Scenario Deck!',
    'Picture this: you\'re on the brink of discovering fire, guiding your tribe through the rough terrain, when suddenly, a twist of fate throws a sabertooth cat into your path. Fear not, for our Scenario Deck is here to elevate your gameplay from \'epic\' to \'prehistorically legendary\'!',
    'Gone are the days of predictable settling and hunting brace yourselves for encounters that will shake your spears and rattle your mammoth bones.',
    'Will Lady Luck smile upon you with a bountiful resource card, filling your stores with sustenance for your journey? Or will misfortune rear its head, as an Encounter card thrusts you into a showdown with a foreign, hostile tribe? Perhaps you\'ll find yourself faced with a decision that could alter the course of your adventure, with outcomes as varied as the stars themselves.',
    'But heed this warning: not all roads lead to prosperity. In the untamed wilderness of Catan\'s dawn, sometimes the path less traveled is fraught with danger. Your decisions may lead to bountiful rewards, but don\'t be fooled into thinking kindness always prevails. In a world where survival is paramount, sometimes cunning and strategy reign supreme.',
    'So gather your fellow tribesmen, sharpen your wit along with your flint, and prepare for a journey through time unlike any other. Adventure awaits, so grab your gear and let\'s make history...'
]